// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

// external dependencies
import classNames from 'classnames/bind';
import { useSnackbar } from 'notistack';
import axios from 'axios';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel';

// material dependencies
import {
  Button,
  Container,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Link,
  OutlinedInput,
  TextField,
  makeStyles,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Typography,
  Grid
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

// internal dependencies
import TermsAndConditions from '../TermsAndConditions'
import PrivacyPolicy from '../PrivacyPolicy'
import StoreTermsAndConditions from '../StoreTermsAndConditions'
import { STORE_ONLINE_PAYMENTS_BY_URL_QUERY } from '../../qgl-queries/storeOnlinePayments';
import { STORE_TOS_BY_URL_QUERY } from '../../qgl-queries/storeToS';
import { capitalizeWord, countries, findValidUrl, formatNumber, getIndicative } from '../../utils/constants';

// assets
import ChevronLeft from '../Icons/ChevronLeft';
import Whatsapp from '../Icons/Whatsapp';

//functions
import { cleanShoppingCart } from '../../utils/constants';

// styles
import SendOrderStyles from './SendOrder.module.scss'
import { useForm } from 'react-hook-form';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
}

const SendOrder = ({ items, store }) => {
  const { /* storeUrl, */ phoneNumber } = useParams();
  const indicative = getIndicative(phoneNumber.toString());
  const history = useHistory();
  const storeUrl = window.location.hostname.split(".")[0]
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [fieldsForm, setFieldsForm] = useState();
  const [isWhatsApp, setIsWhatsApp] = useState([])
  const [focusInputState, setFocusInputState] = useState(false);
  const [storeOnlinePayments, setStoreOnlinePayments] = useState(false);
  const [code, setCode] = useState(indicative);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [openStoreTermsDialog, setOpenStoreTermsDialog] = useState(false);
  const [sent, setSent] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [storeToS, setStoreToS] = useState('');
  
  const [total] = useState(localStorage.getItem('totalForProps'))
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]); /* Symbols not allowed in numeric input  */
  const userStore = store?.userStores?.find(uStore => uStore.mobilePhoneNumber === phoneNumber);

  // Store query
  const { data } = useQuery(STORE_ONLINE_PAYMENTS_BY_URL_QUERY, {
    variables: { url: storeUrl }
  });

  // Store terms and conditions query
  const { data: storeTosData } = useQuery(STORE_TOS_BY_URL_QUERY, {
    variables: { url: storeUrl }
  });

  const { register, handleSubmit, formState: { isValid } } = useForm({
    mode: 'onChange',
  });
  const onSubmit = data => {
    const infoForm = Object.entries(data).filter(([key, value]) => value !== "" && value !== null && value !== false)
    localStorage.setItem('StepData', JSON.stringify({
      name: infoForm[0][1],
      phone: infoForm[1][1],
    }))

    saveOrder(data, infoForm)
  }

  // functions
  const goBack = () => history.push(`/${phoneNumber}/order/products`);
  const handleCodeChange = newValue => setCode(newValue);
  const handleClosePrivacyDialog = () => setOpenPrivacyDialog(false);
  const toggleFocusInput = state => setFocusInputState(state);
  const handleCloseTermsDialog = () => setOpenTermsDialog(false);
  const handleCloseStoreTermsDialog = () => setOpenStoreTermsDialog(false);

  const handleOpenTermsDialog = (e) => {
    e.preventDefault();
    setOpenTermsDialog(true);
  }
  const handleOpenPrivacyDialog = (e) => {
    e.preventDefault();
    setOpenPrivacyDialog(true);
  }
  const handleOpenStoreTermsDialog = (e) => {
    e.preventDefault();
    setOpenStoreTermsDialog(true);
  }

  const normalizeDateRange = order => {
    const range = order?.filter(dato => typeof dato[1] === 'object' && dato[1]?.desde && dato[1] !== null)
    return range.map(r => `*${r[0]}:* ${Object.entries(r[1])?.map(i => `_${capitalizeWord(i[0])}_ - ${capitalizeWord(i[1])}`).join(', ')}\n`).join(' ')
  }

  const saveOrder = (dataForm, infoForm) => {
    setSent(true);
    const result = infoForm && infoForm.filter(info => (info[0] !== 'name' && info[0] !== 'phone' && typeof info[1] !== 'object' && info[0] !== 'termsCheck'))
    const objectEntries = normalizeDateRange(infoForm)
    
    const messageUrl = `https://wa.me/${userStore.directSaleEnabled ? `${code}${dataForm.phone}` : phoneNumber}?text=`
          const products = allItems.map(item => {
            let product = ''
            if (item.productOptions.length) {
              product = item.productOptions.reduce((acc, current) => {
                if (current.productOptionValues.length > 0) {
                  const currentProduct = current.productOptionValues.find((pov, i) => pov.id === current.selected)
                  return acc + `${currentProduct.name}, `
                }
                return ''
              }, '');
            }
            return `- ${item.amount} *${item.name} ${item.sku ? item.sku : ''}* ${product ? '- ' + product : ''}/  ${item.price > 0 ? `_${formatNumber((item.productListDiscountPrice || item.price), store.currency)}_` : ''} \n`
          });
          
    
    axios.post(`${process.env.REACT_APP_API_URL}/wa10x/${store.id}/${phoneNumber}/order`, {
      order_details: allItems.map(item => ({
        product_id: item.id,
        price: item.productListDiscountPrice || item.price,
        quantity: item.amount,
        options: item.productOptions.map(option => +option.selected),
        ...item.variantId && { product_variant_id: +item.variantId }
      })),
      customer: {
        first_name: dataForm.name.split(' ').shift(),
        last_name: dataForm.name.split(' ').pop(),
        phone_number: `${code}${dataForm.phone}`,
      },
      user_store_id: +userStore.id,
      direct_sale: userStore.directSaleEnabled,
      order_method_id: 1
    })
      .then((orderRes) => {

        const message = `   
Hola, soy ${dataForm.name},
quiero hacer este pedido en ${store.name}:
========================\n
${products.map(product => product.trim()).join('\n')}
========================\n
TOTAL: *${total}*
========================\n
${store.deliveryCostEnabled ? `*Envío:* ${store.deliveryCostDetails || ``}` : ``}
${store.shippingTimeEnabled ? `*Tiempo de entrega:* ${store.shippingDetails || ``}` : ``}
========================\n
Mi información:\n
*Nombre:* ${dataForm.name}\n *Celular:* +${code}${dataForm.phone}\n ${result && result.map(dato => `*${dato[0]}:* ${dato[1]}\n`).join(' ')} ${objectEntries && objectEntries}
========================\n
Mi pedido: ${window.location.origin}/order-review/${store.id}/${orderRes?.data?.id}
========================\n
        `
        if(result.length === 0 && !objectEntries){
          setSent(false)
          axios.get(`${process.env.REACT_APP_API_URL}/stores/${store.id}/orders/${orderRes.data.id}/order-notification`)
          .then(res => {
            cleanShoppingCart()
            window.location.href = `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`
          })
          .catch(err => console.log(err))
          
        }else{
          const arrToString = arr => arr.join(', ')
          const objTostring = obj => JSON.stringify(obj)
          const mappedResponses = fieldsForm.map( resField => ({
            form_id: resField.form_id,
            form_field_id: resField.id,
            type: resField.type,
            title: resField.title,
            response: resField.type === 'checkbox' && dataForm[resField.title] ? arrToString(dataForm[resField.title]) : '' || resField.type === 'range' && objTostring(dataForm[resField.title]) || dataForm[resField.title],
            order_id: orderRes.data.id
          }) )

          const filterNoRes = mappedResponses?.filter(v => v.response !== "" && v.response !== null && v.response !== false)
          
          if(filterNoRes.length > 0) {
            axios.post(`${process.env.REACT_APP_API_URL}/stores/${store.id}/orders/${orderRes.data.id}/dynamic-form-responses`, filterNoRes)
            .then((response) => {
              setSent(false)
              axios.get(`${process.env.REACT_APP_API_URL}/stores/${store.id}/orders/${orderRes.data.id}/order-notification`)
              .then(res => {
                cleanShoppingCart()
                ReactPixel.track('Purchase', {
                  content_type: 'checkout',
                  num_items: items.length,
                })
                window.location.href = `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`
              })
              .catch(err => console.log(err))
            })
            .catch( err => console.log(err))
          }
        }
      })
      .catch((err) => {
        setSent(false);
        enqueueSnackbar('No pudimos crear el pedido, intentalo de nuevo', {
          variant: 'error',
          autoHideDuration: 2500
        })
      })
  }

  const numberWithDots = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  /* EFFECTS HERE ⬇️ */

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/stores/${store.id}/dynamic-form`)
      .then(res => {
        const { data } = res;
        const { fields } = data;

        setFormData(data);
        setFieldsForm(fields);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, [store.id])

  useEffect(() => {
    if (data) {
      if (data.store === null) {
        history.push('/404');
      } else {
        setStoreOnlinePayments(data.store.onlinePayments);
      }
    }
    if (store.id) findValidUrl(store, phoneNumber, () => history.push('/404'));
  }, [data, history, phoneNumber, store]);

  useEffect(() => {
    if (storeTosData) {
      setStoreToS(storeTosData.store.termsAndConditions)
    }
  }, [storeTosData]);

  useEffect(() => {
    const localAllItems = items.filter(item => item.amount !== 0)

    /* if (localAllItems.length === 0) {
      history.push('/')
    } */

    items.forEach(item => {
      item.extraVariants.forEach(variant => {
        localAllItems.push({
          sku: variant.sku,
          name: item.name,
          id: item.id,
          price: variant.price || item.price,
          amount: variant.amount,
          productOptions: variant.options.map(option => ({
            selected: option,
            productOptionValues: item.productOptions.find(
              productOption => productOption.productOptionValues
                .map(pov => pov.id)
                .indexOf(option) !== -1
            ).productOptionValues
          })),
          variantId: variant.variantId
        })
      })
    });

    const compare = (a, b) => {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0;
    }

    setAllItems(localAllItems.sort(compare));
  }, [items]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store?.id}/payment-channels-store`)
      .then((response) => {
        setIsWhatsApp(response?.data.filter(item => item?.payment_channel?.name === 'Whatsapp'))
      })
      .catch((error) => {
        console.log(error);
      })


    axios.get(`${process.env.REACT_APP_API_URL}/stores/${store?.id}/shipping`)
    .then(res => console.log(res))
    .catch((error) => {
      console.log(error)
    })
  }, [store])

  //Facebook pixel
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
      store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
        content_type: 'checkout',
        content_name: items.map(item => item.name).join(', '),
        num_items: items.length,
      });
    }
  }, [items, store])
  /* EFFECTS HERE ⬆️ */

  
  const whatsAppFieldClass = classNames({
    [SendOrderStyles.numberField]: true,
    [SendOrderStyles.numberFieldFocus]: focusInputState
  });

  return (
    <>
      {loading && <CircularProgress style={{ margin: '1rem' }} />}
      {!loading && <Container
        className={SendOrderStyles.sendOrderContainer}
        maxWidth="sm"
        disableGutters>
        <header className={SendOrderStyles.topHeader}>
          <IconButton
            onClick={goBack}
            color="inherit"
            aria-label="regresar">
            <ChevronLeft fontSize="small" />
          </IconButton>
          <Typography variant="h1" component="h1">{formData ? formData.title : 'Completa tu información'}</Typography>
        </header>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: '20px' }}>
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth>
              <FormLabel htmlFor='name'>Ingresa tu nombre y tu apellido</FormLabel>
              <OutlinedInput
                placeholder="Ingresa tu nombre completo"
                id='name' {...register("name", { required: true })}
                fullWidth />
            </FormControl>

            <div className={SendOrderStyles.numberFieldWrapper}>
              <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel focused={focusInputState}>Ingresa tu Whatsapp</FormLabel>
                <div
                  className={whatsAppFieldClass}>
                  <div className={SendOrderStyles.numberFieldIcon}>
                    <Whatsapp fontSize="small" />
                  </div>
                  <div className={SendOrderStyles.numberFieldCode}>
                    <CountryAutocomplete
                      id="customerWhatsappCountry"
                      style={{ width: "100%" }}
                      options={countries}
                      size="small"
                      disableClearable
                      disableListWrap
                      noOptionsText="No hay resultados"
                      value={code || ''}
                      onChange={(event, value) => handleCodeChange(value.phone)}
                      getOptionLabel={option => option.phone || code}
                      renderOption={option => (
                        <>
                          <span>{countryToFlag(option.code)}</span>{`${option.label} +${option.phone}`}
                        </>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="(+00)"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable browser autocomplete and autofill
                          }}
                        />
                      )} />
                  </div>
                  <FormControl className={SendOrderStyles.numberFieldWhatsapp}>
                    <Input
                      required
                      id="customerWhatsapp"
                      placeholder="Ingresa tu número de WhatsApp"
                      type="number"
                      onFocus={() => toggleFocusInput(true)}
                      onBlur={() => toggleFocusInput(false)}
                      {...register("phone", { required: true })}
                      disableUnderline
                      fullWidth />
                  </FormControl>
                </div>
              </FormControl>
            </div>

            <>
              {fieldsForm && fieldsForm.map(field => (
                <>
                  {field.type === 'input'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <OutlinedInput
                        id={field.title}
                        placeholder={field.text}
                        {...register(field.title, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </FormControl>
                  }
                  {field.type === 'email'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <OutlinedInput
                        id={field.title}
                        placeholder={field.text}
                        type="email"
                        {...register(field.title, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </FormControl>
                  }
                  {field.type === 'number'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <OutlinedInput
                        id={field.title}
                        placeholder={field.text}
                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                        type="number"
                        {...register(field.title, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </FormControl>
                  }
                  {field.type === 'textarea'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <OutlinedInput
                        id={field.title}
                        placeholder={field.text}
                        minRows={4}
                        multiline={true}
                        {...register(field.title, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </FormControl>
                  }
                  {field.type === 'select'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <Select
                        labelId={field.title}
                        id={field.title}
                        label={field.title}
                        {...register(field.title, { required: field.mandatory ? true : false })}
                      >
                        <MenuItem value='' disabled>Selecciona una opción...</MenuItem>
                        {field.options.map(opc => (
                          <MenuItem key={opc} value={opc}>{opc}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  {field.type === 'radio'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel id={field.title}>{field.title}</FormLabel>
                      <RadioGroup
                        aria-labelledby={field.title}
                        name={field.title}
                      >
                        {field.options.map(opc => (
                          <label value={opc}>
                            <FormControlLabel
                              {...register(field.title, { required: field.mandatory ? true : false })}
                              type="radio"
                              name={field.title}
                              control={<Radio color="primary" />}
                              label={opc}
                              value={opc}
                              id={opc}
                            />
                          </label>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  }
                  {field.type === 'checkbox'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel>{field.title}</FormLabel>
                      {field.options.map(opc => (
                        <FormControlLabel control={<Checkbox value={opc} color="primary" />} label={opc} {...register(field.title, { required: field.mandatory ? true : false })} />
                      ))}
                    </FormControl>
                  }
                  {field.type === 'label'
                    && <section>
                      <h3>{field.title}</h3>
                      <p>{field.text}</p>
                    </section>
                  }
                  {field.type === 'date'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <OutlinedInput
                        id={field.title}
                        className={SendOrderStyles.dateField}
                        placeholder="dd/mm/aaaa"
                        type="date"
                        {...register(field.title, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </FormControl>
                  }
                  {field.type === 'time'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <OutlinedInput
                        id={field.title}
                        placeholder="--:--"
                        className={SendOrderStyles.dateField}
                        type="time"
                        {...register(field.title, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </FormControl>
                  }
                  {field.type === 'range'
                    && <FormControl
                      key={field.id}
                      variant="outlined"
                      margin="normal"
                      fullWidth>
                      <FormLabel htmlFor={field.title}>{field.title}</FormLabel>
                      <br />

                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            style={{ color: '#53627C', fontSize: 16 }}
                          >
                            Desde:
                          </Typography>
                          <OutlinedInput
                            id={field.title}
                            className={SendOrderStyles.dateField}
                            placeholder="dd/mm/aaaa"
                            label="Desde:"
                            type="date"
                            {...register(`${field.title}.desde`, { required: field.mandatory ? true : false })}
                            fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            style={{ color: '#53627C', fontSize: 16 }}
                          >
                            Hasta:
                          </Typography>
                          <OutlinedInput
                            id={field.title}
                            className={SendOrderStyles.dateField}
                            placeholder="dd/mm/aaaa"
                            label="Hasta:"
                            type="date"
                            {...register(`${field.title}.hasta`, { required: field.mandatory ? true : false })}
                            fullWidth />
                        </Grid>
                      </Grid>
                    </FormControl>
                  }
                </>
              ))}
            </>

            <CheckboxTerms
              control={
                <Checkbox
                  className={SendOrderStyles.checkbox}
                  {...register("termsCheck", { required: true })}
                  required
                  color="primary"
                />
              }
              label={(
                <>
                  <span>He leído y acepto <span>los <Link href="#" onClick={handleOpenTermsDialog}>Términos y Condiciones</Link> y <Link href="#" onClick={handleOpenPrivacyDialog}>Política de Privacidad</Link> de 10X y</span> {storeToS ? <span> las <Link href="#" onClick={handleOpenStoreTermsDialog}>Políticas de tratamiento de la información</Link> de {store.name}</span> : <span>las Políticas de tratamiento de la información de {store.name}</span>}</span>
                </>
              )}
            />

            <TermsAndConditions
              open={openTermsDialog}
              openHandler={handleOpenTermsDialog}
              closeHandler={handleCloseTermsDialog}
            />

            <PrivacyPolicy
              open={openPrivacyDialog}
              openHandler={handleOpenPrivacyDialog}
              closeHandler={handleClosePrivacyDialog}
            />

            <StoreTermsAndConditions
              open={openStoreTermsDialog}
              openHandler={handleOpenStoreTermsDialog}
              closeHandler={handleCloseStoreTermsDialog}
              storeToS={storeToS}
            />
            {isWhatsApp[0]?.active &&
              <Button
                disabled={isValid ? false : true}
                type='submit'
                className={SendOrderStyles.sendOrderBtn}
                variant="contained"
                startIcon={<Whatsapp fontSize="small" />}
                fullWidth>
                {store.callToActionButton || `Ordenar por WhatsApp`}
                {sent &&
                  <CircularProgress
                    size={24}
                    className={SendOrderStyles.sendOrderBtnProgress}
                  />
                }
              </Button>}
          </form>
        </Container>
      </Container>}
    </>
  )
}

// Customize the Autocomplete Component
function CountryAutocomplete(props) {
  const classes = countryAutoCompleteStyles()
  return (
    <Autocomplete classes={classes} {...props} />
  )
}

// Customize the FormControlLabel Checkbox Component
function CheckboxTerms(props) {
  const classes = checkboxTermsStyles()
  return (
    <FormControlLabel classes={classes} {...props} />
  )
}

// Material-UI Styles
const countryAutoCompleteStyles = makeStyles(theme => ({
  popper: {
    width: '300px !important',
    left: '16px !important'
  },
  option: {
    fontSize: 13,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    padding: '6px 0 !important',
  }
}))

const checkboxTermsStyles = makeStyles(theme => ({
  root: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store,
});

//const mapDispatchToProps = dispatch => bindActionCreators({ setCartState }, dispatch);

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(SendOrder);